import { makeInMemoryStorage } from '@/services/in-memory-storage';

type CreateStorageParams = {
  prefix?: string;
  getStorage: () => Storage;
};

const createStorage = (
  getStorage: CreateStorageParams['getStorage'],
): Storage => {
  try {
    return getStorage();
  } catch {
    return makeInMemoryStorage();
  }
};

const getPrefixed = (prefix: string, key: string) => prefix + key;

const logStorageError = (err: unknown) => {
  console.log('Storage error:', err);
};

export const createStorageService = ({
  prefix = 'sy-',
  getStorage,
}: CreateStorageParams): Storage => {
  const storage = createStorage(getStorage);

  return {
    getItem(key) {
      try {
        return storage.getItem(getPrefixed(prefix, key));
      } catch (err) {
        logStorageError(err);
        return null;
      }
    },
    setItem(key, value) {
      try {
        storage.setItem(getPrefixed(prefix, key), value);
      } catch (err) {
        logStorageError(err);
      }
    },
    clear() {
      try {
        storage.clear();
      } catch (err) {
        logStorageError(err);
      }
    },
    key(index) {
      try {
        return storage.key(index);
      } catch (err) {
        logStorageError(err);
        return null;
      }
    },
    removeItem(key) {
      try {
        storage.removeItem(getPrefixed(prefix, key));
      } catch (err) {
        logStorageError(err);
      }
    },
    length: storage.length,
  };
};
