import { localStorageService } from '@/services/local-storage-service.ts';

type PushSettings = Record<string, boolean>;

const getPushSettings = (): PushSettings => {
  try {
    const persisted = localStorageService.getItem('pushSettings');
    return (persisted && JSON.parse(persisted)) || {};
  } catch {
    return {};
  }
};

const persistPushSettings = (v: PushSettings) =>
  localStorageService.setItem('pushSettings', JSON.stringify(v));

export const pushService = {
  getPushSettings,

  getSubscription: async () => {
    if (Notification.permission !== 'denied') {
      const registration = await navigator.serviceWorker.ready;
      return await registration.pushManager.getSubscription();
    }
  },

  subscribe: async () => {
    if (Notification.permission === 'granted') {
      const registration = await navigator.serviceWorker.ready;
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: import.meta.env.VITE_PUSH_SERVER_PUBLIC_KEY,
      });
    }
  },

  addUserToPushSettings: (id: string) => {
    let pushSettings = pushService.getPushSettings();
    if (pushSettings) {
      pushSettings[id] = true;
    } else {
      pushSettings = {
        [id]: true,
      };
    }
    persistPushSettings(pushSettings);
  },

  removeUserFromPushSettings: (id: string) => {
    let pushSettings = pushService.getPushSettings();
    if (pushSettings) {
      pushSettings[id] = false;
    } else {
      pushSettings = {
        [id]: false,
      };
    }
    persistPushSettings(pushSettings);
  },
};
