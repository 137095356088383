export const makeInMemoryStorage = (): Storage => {
  const store: Map<string, string> = new Map();

  return {
    get length() {
      return store.size;
    },
    clear() {
      store.clear();
    },
    key(index) {
      return [...store.keys()][index];
    },
    getItem(key) {
      return store.get(key) ?? null;
    },
    removeItem(key) {
      store.delete(key);
    },
    setItem(key, value) {
      store.set(key, value);
    },
  };
};
